import { EntityId } from "@reduxjs/toolkit";
import { UsersState } from "../slice/userSlice";
import { StoresState } from "../slice/storeSlice";
import { postApiSlice } from "../api/postApi";
import { userApiSlice } from "../api/userApi";
import { articleApiSlice } from "../api/articles";
import ApiCall from "../api/api";
import { questionsApiSlice } from "../api/questions";
import { storeApiSlice } from "../api/stores";

export interface UserData {
  name: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  selectOption: string;
  error?: boolean;
  newPassword: string;
  values: string;
  role: string;
}
export interface LanguageContextType {
    language: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
  }
  
  export interface LanguageModalProps {
    isOpen: boolean;
    onClose: () => void;
  }


  export  interface PostContentProps {
    post: Post;
    isAuthenticated: boolean;
    currentUser: User;
    handlePostCardClick: (postId: string) => void; 
    isLoading: any;
    updatedPostData: any;
  }
  
  export interface Articles {
    verify: any;
    id: string;
    comments: {
      _id: any; 
      user: UserPost; 
      comment: string;
      createdAt: string;
}[];
    createdAt: string;
    htmlContent: string;
    images: string[];
    keywords: string[];
    likes: string[];
    pdfs: string[];
    title: string;
    user: UserPost;
    _id: string;
  }
  
  
  export interface ArticleContentProps {
    articles: Articles[];
    isAuthenticated: boolean;
    // currentUser: User;
    handleArticleClick: (postId: string) => void;
    // isLoading: any;
    updateArticleData: any;
  }
  
  export interface User {
    id: EntityId; 
    name: string;
    username: string;
    email: string;
    password: string;
    phoneNumber: string;
    role: string;
    location?: string
    field: string;
    about: string;
    confirmPassword: string;
  }
  export interface Update<User> {
    id: string; 
    changes: Partial<User>; 
  }  

  export interface UserState {
    isLogin: boolean;
    expiringDate?: string;
  }


// types.ts or in the file where you define your types
export interface SearchResult {
  htmlContent: string;
  _id: number;
  name?: string; // Keep this optional as some results might not have a name
  images?: string[];
  type: "article" | 'post' ;
  title?: string; // Title for articles
  uses: string;
  description: string;
  otherNames: string[];
}

export type UserPost = {
  _id: string;
  username: string;
  name: string;
};

// Interface representing a User in a Question
export interface UserQuestion {
  _id: string;
  name?: string; // Optional because the user object in the answer doesn't have 'name'
  username: string;
  role?: string; // Optional because the role might not always be present
}

// Interface representing an Answer
export interface Answer {
  _id: string;
  user: UserQuestion;
  likes: string[]; // Array of user IDs who liked the answer
  dislikes: string[]; // Array of user IDs who disliked the answer
  verify: boolean;
  answer: string;
  createdAt: string;
}

// Interface representing a Question
export interface Question {
  _id: string;
  id: string;
  images: string[]; // Array of image URLs
  question: string;
  lang: string;
  user: UserQuestion;
  answers: Answer[]; // Array of Answer objects
  createdAt: string;
}

// Interface representing the API response structure
export interface QuestionApiResponse {
  statusCode: number;
  message: string;
  data: Question[]; // Array of Question objects
}


export interface Post {
  updatedAt: string;
  location: string;
  _id: string;
  likes: string;
  dislikes: string;
  comments: {
    createdAt: string; _id: string; user: UserPost; comment: string 
}[];
  images: string[];
  name: string;
  othernames: string[];
  uses: string;
  description: string;
  type: string;
  verify: boolean;
  lang: string;
  user: UserPost;
  createdAt: string;
}

export enum ItemCategory {
  OIL = "Oils",
  SOAP = "Soaps",
  HERBAL_MEDICINE = "Herbal Medicines",
  SPICE = "Spices",
  HERBS = "Herbs",
  SEED = "Seeds",
  SPIRITUAL_ITEMS = "Spiritual Items",
  HERBAL_TEA = "Herbal teas",
  CANDLES = "Candles",
  BEADS = "Beads",
  PLANTS = "Plants"
}


export type Store = {
  image: string | undefined;
  // id: string;
  _id: string;
  description: string;
  name: string;
  deactivated: boolean;
  tokenAvailable: number;
  category: string[];
  createdAt: string;
  user: UserPost;
  phoneNumber: string;
};

export interface ProductVariant {
  size: string;
  price_dollar: number;
  price_naira: number;
}

export interface Product {
createdAt: string;
updateAt: string;
store: Store;
_id: string;
name: string;
description: string;
category: string;
images: string[];
quantity: number;
naira_price: number;
dollar_price: number;
prices: ProductVariant[];
review: Review[];
}

export interface Review {
_id: string;
user: UserPost;
rating: number;
comment: string;
}

const { apiSlice } = ApiCall();

export interface RootState  {
  state: any;
  reverseGeocode: any;
  posts: ReturnType<typeof postApiSlice.reducer>;
  language: any;
  users: UsersState;
  stores: StoresState; 
  api: {
    postsApi: ReturnType<typeof postApiSlice.reducer>;
    userApi: ReturnType<typeof userApiSlice.reducer>;
    articles: ReturnType<typeof articleApiSlice.reducer>; 
    apiSlice:  ReturnType<typeof apiSlice.reducer>; 
    questions: ReturnType<typeof questionsApiSlice.reducer>;
    storesApi: ReturnType<typeof storeApiSlice.reducer>;
  };
}

  export interface Item {
    type:  string | { [key: string]: string };
  }
  
  export  type FilterOption = {
    id: number;
    icon: string;
    type:  string | { [key: string]: string };
  };


  

export interface PostCardProps {
  posts: Post[];
}
export  interface IFormInputs {
  email: string;
  username: string;
  name: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  selectOption: string;
  role: string;
  icons: {
    visible: string;
    hidden: string;
  };
  field: string;
  otherField: string;
  about: string;
}
