// ContentDisplay.tsx
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import UserPosts from '../../components/User/UserPosts';
import UserArticle from '../../components/User/UserArticle';
// import UserStores from '../Stores/UserStores';
import UserQuestions from '../../components/User/UserQuestions';
import PersonalUserStores from '../Stores/PersonalUserStores';


  
export interface UserInfoProps {
  selectedSegment: string;
  userId: any;
  userData: any; 
  }

const ContentDisplay: React.FC<UserInfoProps> = ({
  selectedSegment,
  userId,
  userData
}) => (
  <Swiper grabCursor centeredSlides pagination={{ clickable: true }}>
    <SwiperSlide>
      {selectedSegment === 'Post' && (
        <UserPosts 
        userId={userId} 
      />
      )}
      {selectedSegment === 'Article' &&  userData?.data?.user?.role === "SPECIALIST" && (
        <UserArticle
          userId={userId}
        />
      )}
      {selectedSegment === "Question" && <UserQuestions
                  userId={userId} /> }
       {selectedSegment === "Stores" && userData?.data?.user?.role === "SPECIALIST" && <PersonalUserStores />}
    </SwiperSlide>
  </Swiper>
);

export default ContentDisplay;