import React, { useState } from 'react';
import { Box, Button, Container, Heading, List, ListItem, Text, Input, useColorMode, FormLabel, Stack, Modal } from '@chakra-ui/react';
import { Product } from '../types/interface';
import ToastNotification from './ToastNotification';

interface CartProps {
  cart: Product[];
  isOpen: boolean;
  toggleCart: () => void;
  language: string;
  setCart: any; // Add this
  getPriceInCurrency: any;
  showDollar: boolean;
  // currentUser: any;
}

const Cart: React.FC<CartProps> = ({ cart, 
  isOpen, 
  toggleCart, 
  language, 
  setCart, 
  getPriceInCurrency, 
  showDollar
   }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<'$' | '₦'>(
    showDollar ? '$' : '₦'
  );
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
  const { colorMode } = useColorMode();
  const toggleCurrency = () => {
    setSelectedCurrency((prevCurrency) => (prevCurrency === '$' ? '₦' : '$'));
  };
  const [modalOpen, setModalOpen] = useState(false);
// State for modal callbacks
const [modalCallbacks, setModalCallbacks] = useState<{
  handleYes: () => void;
  handleNo: () => void;
} | null>(null);

  const [currentStoreId, setCurrentStoreId] = useState<string | null>(null);

  

  const total = cart.reduce((runningTotal, product) => {
    if (product.prices && product.prices.length > 0) {
      // If product has variant prices
      const variantTotal = product.prices.reduce((sum, price) => {
        const priceValue = showDollar
          ? Number(price.price_dollar || 0)
          : Number(price.price_naira || 0);
        return sum + priceValue * (product.quantity || 1);
      }, 0);
      return runningTotal + variantTotal;
    } else {
      // Fallback to product-level price
      const fallbackPrice = showDollar
        ? Number(product.dollar_price || 0)
        : Number(product.naira_price || 0);
      return runningTotal + fallbackPrice * (product.quantity || 1);
    }
  }, 0);
  
  

  const handleSendToStore = async (storeId: string | null) => {
    if (!storeId) return;
    // console.log("Store Id: ", storeId)

    const storeItems = cart.filter((item) => item?.store?._id === storeId);
    const store = storeItems[0]?.store;
    // console.log("Store: ", store)
    const storePhoneNumber = store?.phoneNumber;
    // console.log("Store Phone Number: ", storePhoneNumber)

    if (!storePhoneNumber) {
      setToastConfig({
        description: language === "en"
          ? `Error! Unable to send the order to store: ${store?.name || 'Unknown'}.`
          : language === "yo"
          ? `Aṣiṣe! Ko ṣeeṣe lati fi aṣẹ ranṣẹ si ile itaja: ${store?.name || 'Aiyewo'}.`
          : `¡Error! No se pudo enviar el pedido a la tienda: ${store?.name || 'Desconocida'}.`,
        status: "error",
      });
      setShowToast(true);
      return;
    }

    const storeTotal = storeItems.reduce((sum, item) => {
      const calculateStoreTotal = (products: any[]) =>
        products.reduce((total: number, product: any) => {
          if (product.prices && product.prices.length > 0) {
            // Sum up prices in the current currency
            return (
              total +
              product.prices.reduce((priceSum: number, price: any) => {
                const priceValue =
                  selectedCurrency === '₦'
                    ? Number(price.price_naira)
                    : Number(price.price_dollar);
                return priceSum + priceValue * (product.quantity || 1);
              }, 0)
            );
          }
    
          // Fallback for products without a price array
          const fallbackPrice =
            selectedCurrency === '₦'
              ? Number(product.naira_price || 0)
              : Number(product.dollar_price || 0);
    
          return total + fallbackPrice * (product.quantity || 1);
        }, 0);
    
      return sum + calculateStoreTotal([item]); // Add the total for the current item to the accumulated sum
    }, 0); // Initial value of sum is 0
    


    const message = `Order from ${name}\nPhone: ${phoneNumber}\nEmail: ${email}\nAddress: ${address}\nTotal: ${getPriceInCurrency(storeTotal, selectedCurrency)}\nItems:\n${storeItems.map(item => `${item.name} - Quantity: ${item.quantity}`).join("\n")}`;
    const whatsappURI = `https://wa.me/${storePhoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappURI, "_blank");
    setToastConfig({
      description: language === "en"
        ? `Success! Order sent to ${store?.name || "Unknown Store"}!`
        : language === "yo"
        ? `Aṣeyọri! Aṣẹ naa ti fi ranṣẹ si ${store?.name || "Aiyewo"}!`
        : `¡Éxito! Pedido enviado a ${store?.name || "Tienda Desconocida"}!`,
      status: "success",
    });
    setShowToast(true);
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    const groupedByStore = cart.reduce((acc: { [key: string]: any[] }, product) => {
      const storeId = product?.store?._id;
      if (storeId) {
        if (!acc[storeId]) acc[storeId] = [];
        acc[storeId].push(product);
      }
      return acc;
    }, {});
  
    const storeIds = Object.keys(groupedByStore);
  
    for (let i = 0; i < storeIds.length; i++) {
      const storeId = storeIds[i];
      setCurrentStoreId(storeId);
      setModalOpen(true);  // Open the modal for the current store
  
      // Use a promise to wait for the user’s response before moving to the next store
      const userResponse = await new Promise<boolean>((resolve) => {
        const handleYes = () => {
          handleSendToStore(storeId);  // Send order to current store
          setModalOpen(false); // Close current modal after successful order
          resolve(true); // Move to next store
        };
  
        const handleNo = () => {
          setModalOpen(false); // Close current modal if user chooses not to proceed
          resolve(false); // Skip this store and break out of loop
        };
  
        setModalCallbacks({ handleYes, handleNo });
      });
  
      if (!userResponse) break; // Stop if user chooses not to proceed with further stores
    }
  
    setToastConfig({
      description:
        language === 'en'
          ? 'Order processed for all applicable stores'
          : language === 'yo'
          ? 'Aṣẹ rẹ ti ṣiṣẹ fun gbogbo awọn ile itaja to yẹ.'
          : 'Pedido procesado para todas las tiendas aplicables.',
      status: 'info',
    });    
    // console.log('Order processed for all applicable stores');
  };
  

  const clearCart = () => {
    setCart([]);
    setToastConfig({
      description:
        language === 'en'
          ? 'Cart cleared successfully!'
          : language === 'yo'
          ? 'Kati rẹ ti di mimọ ni aṣeyọri!'
          : '¡Carrito borrado con éxito!',
      status: 'info',
    });
    setShowToast(true);
  };
  

  return (
    <Container textAlign={'center'} lineHeight={'2'}>
      <Box className={`cart ${isOpen ? 'open' : ''}`} mt={6}>
        <Heading textAlign={'start'}>
          {language === 'en' ? 'Your Cart' : language === 'yo' ? 'Cart Rẹ' : 'Tu Carrito'}
        </Heading>
         <List>
            {cart.map((item, index) => {
              if (typeof item !== 'object' || !item) {
                console.error('Invalid item in cart:', item);
                return null; // Skip invalid items
              }

              const priceDisplay = item.prices?.length
                ? `  ${showDollar ? item.prices[0].price_dollar : item.prices[0].price_naira}
                      ${showDollar ? '$' : '₦'}`
                : `${showDollar ? item.dollar_price : item.naira_price} ${showDollar ? '$' : '₦'}`;

                const sizeInfo = item.prices.map(price => price.size ? ` - Size: ${price.size}` : '').join(', ') || ''; 
              return (
                
                <ListItem key={index}>
                <strong>{item?.store?.name}</strong> {/* Display store name */}
                <br />
                {item?.name} 
                - {priceDisplay} - 
                Quantity: {item?.quantity || 1}
                {sizeInfo}
              </ListItem>              
              );
            })}
          </List> 

      
          <Text>
            {language === 'en' ? 'Total' : language === 'yo' ? 'Lapapọ' : 'Total'}:{" "}
            {getPriceInCurrency(total, showDollar)}
          </Text>



        <form onSubmit={handleFormSubmit}>
          <FormLabel>
            {language === 'en' ? 'Your Name' : language === 'yo' ? 'Oruko Rẹ' : 'Tu Nombre'}
          </FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder={
              language === "en"
                ? "Name..."
                : language === 'yo'
                  ? 'Oruko...'
                  : 'Nombre...'
            }
            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
            borderColor={colorMode === 'light' ? '#345430' : 'white'}
            fontFamily="Inter"
            fontSize="20px"
            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
            width={"100%"}
            height={"60px"}
            mb={5}
            mt={5}
          />
          <FormLabel>
            {language === 'en' ? 'Your Email' : language === 'yo' ? 'Email Rẹ' : 'Tu Correo Electrónico'}
          </FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder={
              language === "en"
                ? "Email..."
                : language === 'yo'
                  ? 'Email re...'
                  : 'Correo Electrónico...'
            }
            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
            borderColor={colorMode === 'light' ? '#345430' : 'white'}
            fontFamily="Inter"
            fontSize="20px"
            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
            width={"100%"}
            height={"60px"}
            mb={5}
            mt={5}
          />
          <FormLabel>
            {language === 'en' ? 'Your Address' : language === 'yo' ? 'Address Rẹ' : 'Tu Dirección'}
          </FormLabel>
          <Input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            placeholder={
              language === "en"
                ? "Address..."
                : language === 'yo'
                  ? 'Address re...'
                  : 'Dirección...'
            }
            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
            borderColor={colorMode === 'light' ? '#345430' : 'white'}
            fontFamily="Inter"
            fontSize="20px"
            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
            width={"100%"}
            height={"60px"}
            mb={5}
            mt={5}
          />
          <FormLabel>
            {language === 'en' ? 'Your Phone Number' : language === 'yo' ? 'Nọmba Foonu Rẹ' : 'Tu Número de Teléfono'}
          </FormLabel>
          <Input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            placeholder={
              language === "en"
                ? "Phone Number..."
                : language === 'yo'
                  ? 'Nọmba Foonu yin...'
                  : 'Número de Teléfono...'
            }
            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
            borderColor={colorMode === 'light' ? '#345430' : 'white'}
            fontFamily="Inter"
            fontSize="20px"
            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
            width={"100%"}
            height={"60px"}
            mb={5}
            mt={5}
          />

              <Button
            type="submit"
            colorScheme={colorMode === 'light' ? 'teal' : 'green'}
            variant="solid"
            mt={5}
          >
            {language === 'en' ? 'Place Order' : language === 'yo' ? 'Gbeṣẹ Ọja' : 'Hacer Pedido'}
          </Button>
        </form>
      </Box>
   <Stack direction={{ base: 'column', md: 'row' }} spacing={4} mt={5}>
  <Button onClick={toggleCurrency}>
    {showDollar ? '$' : '₦'}
  </Button>
  <Button onClick={toggleCart}>
    {language === 'en' ? 'Close Cart' : language === 'yo' ? 'Pa Cart' : 'Cerrar Carrito'}
  </Button>
  <Button
    bgColor={colorMode === 'light' ? '#FF6F6F' : '#9E3636'}
    color={colorMode === 'light' ? 'black' : 'white'}
    onClick={clearCart}
    
  >
    {language === 'en' ? 'Clear Cart' : language === 'yo' ? 'Pa Kati Rẹ' : 'Borrar Carrito'}
  </Button>
</Stack>
<Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
  <div style={{
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.9)",
    color: "#000000", 
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    textAlign: "center",
    width: "300px"
  }}>
    <Text style={{ marginBottom: "20px", fontSize: "16px", fontWeight: "bold", color: "#000000" }}>
      {language === "en"
        ? `Send order to ${cart.find(item => item?.store?._id === currentStoreId)?.store?.name}?`
        : language === "yo"
        ? `Ṣe o fẹ lati fi aṣẹ ranṣẹ si ${cart.find(item => item?.store?._id === currentStoreId)?.store?.name}?`
        : `¿Enviar el pedido a ${cart.find(item => item?.store?._id === currentStoreId)?.store?.name}?`}
    </Text>
    <div style={{ display: "flex", justifyContent: "space-around", gap: "10px" }}>
      <Button
        onClick={() => {
          modalCallbacks?.handleYes();
          setModalOpen(false); // Close the modal after confirming
        }}
        style={{ backgroundColor: "#4CAF50", color: "white", padding: "10px", borderRadius: "5px" }}
      >
        {language === "en" ? "Yes" : language === "yo" ? "Bẹẹni" : "Sí"}
      </Button>
      <Button
        onClick={() => {
          modalCallbacks?.handleNo();
          setModalOpen(false); // Close the modal if cancelled
        }}
        style={{ backgroundColor: "#F44336", color: "white", padding: "10px", borderRadius: "5px" }}
      >
        {language === "en" ? "No" : language === "yo" ? "Rara" : "No"}
      </Button>
    </div>
  </div>
  <div style={{
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 999
  }} onClick={() => setModalOpen(false)} />
</Modal>



        {showToast && (
        <ToastNotification
        description={toastConfig.description}
        status={toastConfig.status}
        onClose={() => setShowToast(false)}
        />
        )}
    </Container>
  );
};


export default Cart;

