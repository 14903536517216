import React, { useRef, useState } from 'react';
import {
  VStack,
  Input,
  Textarea,
  Button,
  Heading,
  Box,
  useColorMode,
  Select,
  Flex,
  Image,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import ToastNotification from '../../components/ToastNotification';
import { useSelector } from "react-redux";
import { MdDelete } from 'react-icons/md';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import axios from 'axios';
import { selectAccessToken, selectCurrentUser } from '../../slice/userSlice';
import { useCreateStoreProductsMutation } from '../../api/stores';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { AutoBackButton } from '../../components';
import { User } from '../../types/interface';

enum ItemCategory {
  OIL = "Oils",
  SOAP = "Soaps",
  HERBAL_MEDICINE = "Herbal Medicines",
  SPICE = "Spices",
  HERBS = "Herbs",
  SEED = "Seeds",
  SPIRITUAL_ITEMS = "Spiritual Items",
  HERBAL_TEA = "Herbal teas",
  CANDLES = "Candles",
  BEADS = "Beads",
  PLANTS = "Plants",
}

interface Price {
  size: string;
  price_dollar: number;
  price_naira: number;
}


interface FormData {
  priceDollar: number;
  priceNaira: number;
  images: string[];
  quantity: string;
  name: string;
  description: string;
  category: ItemCategory;
  prices: Price[];
}


const CreateStoreProducts: React.FC= () => {
  const [searchParams] = useSearchParams();
  const maxProducts = parseInt(searchParams.get('maxProducts') || '0');
  const [remainingTokens, setRemainingTokens] = useState(maxProducts);
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(selectAccessToken);
  const currentUser = useSelector(selectCurrentUser) as User;
  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const {
    handleSubmit,
    control,
    register,
    formState: { isValid, isSubmitting },
    reset,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      priceNaira: 0,
      priceDollar: 0,
      images: [],
      quantity: '',
      name: '',
      description: '',
      category: ItemCategory.OIL,
      prices: [{ size: '', price_dollar: 0, price_naira: 0 }],
    },
    mode: 'onBlur',
  });

  const { fields: variantFields, append: appendVariant, remove: removeVariant } = useFieldArray({
    control,
    name: "prices"
  });

  const { storeId } = useParams<{ storeId: string }>();
  const [createStoreProducts] = useCreateStoreProductsMutation();

  // This is your main file upload handler
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const filesArray = Array.from(files);
      const fileUrls: string[] = [];

      setLoading(true);

      for (const file of filesArray) {
        if (file.type.startsWith('image/')) {

          setToastConfig({
            description: 'Uploading image...',
            status: 'info',
          });
          setShowToast(true);

          const formData = new FormData();
          formData.append('files', file);

          try {
            const response = await axios.post(
              `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/upload`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${accessToken}`,
                  'Access-Control-Allow-Origin': '*/*',
                },
              }
            );

            // console.log("response upload", response)

            if ('data' in response && response?.data?.data) {
              const fileUrl = response.data.data;
              fileUrls.push(fileUrl);
            }
          } catch (error) {
            console.error('An error occurred while uploading images:', error);
            setToastConfig({
              description: 'An error occurred during the upload.',
              status: 'error',
            });
            setShowToast(true);
          }
        }
      }

     
    if (fileUrls.length > 0) {
      setValue('images', fileUrls); // Store URLs in form data
      setPreviewImages((prevImages) => [...prevImages, ...fileUrls]);
      setFileUrls((prevUrls) => [...prevUrls, ...fileUrls]);
      setToastConfig({
        description: 'Success! Images uploaded successfully.',
        status: 'success',
      });
      setShowToast(true);
    }

      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (selectedFiles.length > maxProducts) {
      setToastConfig({
        description: `Error: You can only upload up to ${maxProducts} products.`,
        status: 'error',
      });
      setShowToast(true);
      return;
    }
  
    try {
      // Construct the JSON payload
      const payload = {
        naira_price: data.priceNaira,
        dollar_price: data.priceDollar,
        quantity: data.quantity,
        name: data.name,
        description: data.description,
        category: data.category,
        images: fileUrls,
        prices: data.prices.map((price) => ({
          size: price.size,
          price_dollar: price.price_dollar,
          price_naira: price.price_naira,
        })),
      };

      // console.log("Payload: ", payload);
      
  
      const response = await createStoreProducts({ storeId, body: payload }).unwrap();
  
      if ('data' in response) {
        // Success response
        const responseData = response.data;
        const message = responseData.message || 'Success! Your product was successfully added.';
        setToastConfig({ description: message, status: 'success' });
        setShowToast(true);


        setRemainingTokens((prev) => {
          const updatedTokens = prev - 1;
        
          // Navigate to Upgrade Plan page if tokens are zero
          if (updatedTokens === 0) {
            navigate(`/user/${currentUser.id}/stores/${storeId}/plan-upgrade`);
          }
        
          return updatedTokens;
        });

        reset();
        navigate(`/stores/${storeId}`);
      } else if ('error' in response) {
        setToastConfig({ description: 'Error: Something went wrong', status: 'error' });
        setShowToast(true);
      }
    } catch (error: any) {
      setToastConfig({ description: error.message || 'An error occurred', status: 'error' });
      setShowToast(true);
    }
  };
  

  const { colorMode } = useColorMode();

  const resetAll = () => {
    setPreviewImages([]);
    setSelectedFiles([]);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <AutoBackButton />
      <motion.div
        style={{
          backgroundColor: colorMode === 'light' ? 'white' : '#345430',
          paddingBottom: '20px',
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
      >
        <Box
          fontFamily="Inter"
          paddingTop={20}
          marginRight={{ base: '15', md: '20' }}
          marginLeft={{ base: '15', md: '20' }}
          pb={20}
        >
          <Heading
            fontFamily="Inter"
            as="h6"
            fontSize={{ base: '20px', md: '30px' }}
            textAlign="center"
          >
            Create Products
          </Heading>
          <Text
           fontFamily="Inter"
           textAlign="start"
           mt={4}
            fontSize="sm" color={colorMode === 'light' ? '#345430' : 'white'} mb={4}
            >Remaining Store Tokens: {remainingTokens}</Text>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" method="POST">
                <VStack spacing={10} align="center" mt={5} mb={5}>
                  <Controller
                    control={control}
                    name="images"
                    render={({ field }) => (
                      <Flex
                        justify="center"
                        align="center"
                        flexDirection="column"
                        borderWidth="2px"
                        borderStyle="dotted"
                        borderColor="gray.300"
                        backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                        borderRadius={20}
                        p={5}
                        w="full"
                        h="auto"
                        {...field}
                      >
                        <FormLabel htmlFor="file-upload" display="none">Upload Images</FormLabel>
                        <Input
                          id="file-upload"
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileUpload} 
                          accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                          multiple
                        />
                        <Button
                          fontFamily="Inter"
                          colorScheme="teal"
                          borderRadius={20}
                          p={5}
                          onClick={() => fileInputRef.current?.click()}
                        >
                          <AiOutlineCloudUpload
                            size="48px"
                            color={colorMode === 'light' ? 'white' : '#345430'}
                            style={{ marginRight: '5px' }}
                          />
                          Upload Images
                        </Button>
                        {previewImages.length > 0 && (
                          <Flex
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            wrap="wrap"
                            gap={4}
                            mt={4}
                          >
                            {previewImages.map((image, index) => (
                              <Box
                                key={index}
                                width="full"
                                height="full"
                                borderRadius="lg"
                                overflow="hidden"
                                position="relative"
                              >
                                <Image
                                  src={image}
                                  alt={`Preview ${index}`}
                                  h="300px"
                                  w="300px"
                                  objectFit="cover"
                                  borderRadius="lg"
                                />
                                <Button
                                  size="xs"
                                  position="absolute"
                                  top="0"
                                  right="0"
                                  colorScheme="red"
                                  onClick={() => {
                                    setPreviewImages(previewImages.filter((_, i) => i !== index));
                                    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
                                  }}
                                >
                                  <MdDelete />
                                </Button>
                              </Box>
                            ))}
                                    {previewImages.length > 0 && (
                                  <Button mt="4" colorScheme="red" onClick={resetAll}>
                                    Reset All
                                  </Button>
                                )}
                          </Flex>
                        )}
                        {loading && <p>Loading...</p>}
                      </Flex>
                    )}
                  />

                  <FormControl isRequired>
                    <FormLabel htmlFor="name">Name of Product</FormLabel>
                    <Input
                      id="name"
                      fontFamily="Inter"
                      placeholder="Name of Product"
                      borderRadius={20}
                      p={5}
                      {...register('name', { required: 'This is required' })}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel htmlFor="description">Product Description</FormLabel>
                    <Textarea
                      id="description"
                      fontFamily="Inter"
                      borderRadius={20}
                      p={5}
                      h={500}
                      placeholder="Product Description"
                      {...register('description', { required: 'This is required' })}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel htmlFor="priceNaira">Price Naira</FormLabel>
                    <Input
                      id="priceNaira"
                      fontFamily="Inter"
                      placeholder="Price Naira"
                      borderRadius={20}
                      p={5}
                      {...register('priceNaira', { required: 'This is required' })}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel htmlFor="priceDollar">Price Dollar</FormLabel>
                    <Input
                      id="priceDollar"
                      fontFamily="Inter"
                      placeholder="Price Dollar"
                      borderRadius={20}
                      p={5}
                      {...register('priceDollar', { required: 'This is required' })}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel htmlFor="quantity">Product Quantity</FormLabel>
                    <Input
                      id="quantity"
                      fontFamily="Inter"
                      placeholder="Product Quantity"
                      borderRadius={20}
                      p={5}
                      {...register('quantity', { required: 'This is required' })}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel htmlFor="category">Select Product Category</FormLabel>
                    <Controller
                      control={control}
                      name="category"
                      render={({ field }) => (
                        <Select
                          id="category"
                          fontFamily="Inter"
                          placeholder="Select Product Category"
                          {...field}
                          value={field.value}
                          onChange={(e) => setValue('category', e.target.value as ItemCategory)}
                        >
                          {Object.entries(ItemCategory).map(([key, value]) => (
                            <option key={key} value={value}>
                              {value}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>

                  <Controller
                control={control}
                name="prices"
                render={({ field }) => (
                  <Box w="full">
                    <Heading as="h4" size="md" textAlign="left" my={4}>
                      Price Variants
                    </Heading>
                    {variantFields.map((variant, variantIndex) => (
                      <Box key={variant.id} mb={5} p={4} borderWidth="1px" borderRadius="lg">
                        <FormLabel>Variant {variantIndex + 1}</FormLabel>
                        <Flex
                          direction={{ base: "column", md: "row" }}
                          gap={{ base: 2, md: 4 }}
                          align="start"
                        >
                        <Box flex="1">
                        <FormLabel htmlFor="priceDollar">Product Size</FormLabel>
                          <Input
                            placeholder="Size (e.g., Small, Medium)"
                            {...register(`prices.${variantIndex}.size`, {
                              // required: 'Size is required',
                            })}
                          />
                          </Box>
                          <Box flex="1">
                          <FormLabel htmlFor="priceDollar">Price Dollar</FormLabel>
                          <Input
                            type="number"
                            placeholder="Price in Dollar"
                            {...register(`prices.${variantIndex}.price_dollar`, {
                              // required: 'Price in Dollar is required',
                            })}
                          />
                          </Box>
                          <Box flex="1">
                            <FormLabel htmlFor="priceDollar">Price Naira</FormLabel>
                          <Input
                            type="number"
                            placeholder="Price in Naira"
                            {...register(`prices.${variantIndex}.price_naira`, {
                              // required: 'Price in Naira is required',
                            })}
                          />
                          </Box>
                          <Button
                            colorScheme="red"
                            onClick={() => removeVariant(variantIndex)}
                            size="sm"
                          >
                            <MdDelete />
                          </Button>
                        </Flex>
                      </Box>
                    ))}
                    <Button
                      colorScheme="blue"
                      onClick={() => appendVariant({ size: '', price_dollar: 0, price_naira: 0 })}
                    >
                      Add Price Variant
                    </Button>
                  </Box>
                )}
              />


                <Button
                  fontFamily="Inter"
                  colorScheme="teal"
                  borderRadius={20}
                  p={5}
                  type="submit"
                  isLoading={isSubmitting}
                  isDisabled={!isValid || isSubmitting}
                >
                  Upload Products
                </Button>
              </VStack>
            </form>

          {showToast && <ToastNotification description={toastConfig.description} status={toastConfig.status} />}
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default CreateStoreProducts;
