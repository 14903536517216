import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import { useSelector } from "react-redux";
import { RootState } from "../types/interface";
import { Box } from '@chakra-ui/react';

const Layout = () => {
  const language = useSelector((state: RootState) => state.language.language); 

  const navigationItems = language === 'en' ? ['Home', 'About', 'Stores ' , 'Donate', 'Project', 'Contact'] 
  : language === 'yo' ? ['Ilé', 'Nípa Wa',  'Ọjà ' ,'Rànwálọ́wọ́', 'Ise Wa',  'Kàn Sí Wa'] 
  : ['Inicio', 'Acerca de', 'Tienda', 'Donar', 'Proyecto' , 'Contacto'];


  const links = ['', 'about', 'market', 'donate', 'project', 'contact']

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box>
      <Navbar navigationItems={navigationItems} activePage={currentPath} links={links} />
      <main className="App">
        <Outlet />
      </main>
      <Footer />
    </Box>
  );
};

export default Layout;
