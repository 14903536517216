import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Text, useColorMode } from '@chakra-ui/react';
import { useCommentOnArticleMutation, useDeleteCommentOnArticleMutation } from '../../api/articles';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import { useParams } from 'react-router-dom';
import { Articles, User } from '../../types/interface';
import { SubmitHandler, useForm } from 'react-hook-form';
import ToastNotification from '../ToastNotification';
import { Link } from 'react-router-dom';
import FormTextarea from '../Form/FormTextarea';
import { FaTrash } from 'react-icons/fa';
import formatCreatedAtDate from '../Date';

interface CommentComponentProps {
  article?: Articles;
  updatedArticleData: any;
}

interface CommentFormData {
  comment: string;
}

const ArticleComment: React.FC<CommentComponentProps> = ({ article, updatedArticleData }) => {
  const [comments, setComments] = useState<{
    _id: string; 
    user: any;
     username: string;
    comment: string ;
    createdAt: string;
}[]>([]);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser) as User;
  const { userId } = useParams();
  const validUserId = userId || currentUser?.id;
  const [commentMutation] = useCommentOnArticleMutation();
  const [deleteCommentMutation] = useDeleteCommentOnArticleMutation();
  const { colorMode } = useColorMode();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  const { control, register, handleSubmit, formState: { errors, isSubmitting, isValid }, reset } = useForm<CommentFormData>({
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<CommentFormData> = async (data) => {
    if (!isAuthenticated || !article?._id || data.comment.trim() === '') return;
  
    const newCommentData = { 
      user: {
        _id: String(currentUser.id),
        username: currentUser.username,
        name: currentUser.name || 'Unknown',
      },
      username: currentUser.username,  
      comment: data.comment, 
      _id: '',
      createdAt: '',
    };
  
    const comment = { comment: data.comment };
  
    try {
      const response = await commentMutation({ userId: validUserId, articleId: article._id, comment }).unwrap();
  
      if (response && response._id) {
        newCommentData._id = response._id;
        newCommentData.createdAt = response.createdAt;
      }
  
      // Update local comments state
      setComments(prevComments => [...prevComments, newCommentData]);
  
      // Update article data with new comments
      updatedArticleData({
        ...article,
        comments: [...comments, newCommentData], // Ensure previous comments are included
      });
  
      setToastConfig({
        description: `Comment Added Successfully`,
        status: 'success',
      });
      setShowToast(true);
  
      reset();
    } catch (error: any) {
      console.error("Error: ", error);
      setToastConfig({
        description: `Failed to add comment: ${error?.message || 'Something went wrong'}`,
        status: 'error',
      });
      setShowToast(true);
    }
  };
  
  const handleDeleteComment = async (commentId: string) => {
    try {
      await deleteCommentMutation({ userId: validUserId, articleId: article?._id, commentId });
      setComments(comments.filter(comment => comment._id !== commentId));
      setToastConfig({ description: `Comment Deleted Successfully`, status: 'success' });
      setShowToast(true);
    } catch (error) {
      console.error("Error deleting comment: ", error);
      setToastConfig({ description: `Failed to delete comment`, status: 'error' });
      setShowToast(true);
    }
  };


  useEffect(() => {
    if (article && article.comments) {
      const transformedComments = article.comments.map(comment => ({
        user: comment.user,
        username: comment.user.username,
        comment: comment.comment,
        _id: comment._id,
        createdAt: comment.createdAt,
      }));
      setComments(transformedComments);
    }
  }, [article]);


  return (
    <Box>
      {comments.map((comment, index) => (
        <Box key={index}             
        _hover={{ boxShadow: "md", transform: "scale(1.02)" }}
        transition="all 0.2s ease-in-out"
         position="relative"
          mt={5} marginRight={{ base: "15" }} marginLeft={{ base: "15" }} display="flex" justifyContent="space-between">
          <Box>
          {isAuthenticated ? (
            <>
              <Link to={`/user/${comment?.user?._id}`}>
                <Text fontSize="16px" fontWeight="bold" color={colorMode === 'light' ? '#345430' : 'white'}>
                  @{comment?.user?.username}
                </Text>
              </Link>
              <Text fontSize="14px" color="gray.500">
                {formatCreatedAtDate(comment?.createdAt)}
              </Text>
              <Text fontSize="16px" color={colorMode === 'light' ? '#345430' : 'white'}>
                {comment?.comment}
              </Text>
            </>
          ) : (
            <Link to={'/login'} />
          )}
        </Box>

        {isAuthenticated && comment.user.username === currentUser?.username && (
          <IconButton
            aria-label="Delete Comment"
            icon={<FaTrash />}
            size="md"
            color={'red'}
            position="absolute"
            top={4}
            right={4}
            _hover={{ bg: "red.600", transform: "scale(1.1)" }}
            onClick={() => handleDeleteComment(comment._id)}
          />
        )}
      </Box>
        
      ))}
      <Box>
        {isAuthenticated && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormTextarea
            name="comment"
            label="Add a Comment.."
            control={control}
            rules={{ required: 'Comment is required', maxLength: 400 }}
            errors={errors}

            // text=''
            register={register}
            aria-invalid={errors?.comment ? 'true' : 'false'}

          />
           {errors?.comment && (
              <Text color="red" fontSize="sm">Maximum character limit exceeded</Text> // Display error message
            )}
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Adding Comments in..."
              backgroundColor={colorMode === 'light' ? '#345430' : "white"}
              color={colorMode === 'light' ? "white" : '#345430'}
              size="lg"
              width={'full'}
              disabled={!isValid}
              mt={2}
            >
              Submit
            </Button>
          </form>
        )}
      </Box>
      {showToast && (
              <ToastNotification
                description={toastConfig.description}
                status={toastConfig.status}
                onClose={() => setShowToast(false)}
              />
            )}
    </Box>
  );
};

export default ArticleComment;
