import { Box, Button, useColorMode } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import GooglePlay from "../assets/Google_Play_Store_badge_EN.svg-1.webp";

const DownloadAppSection = () => {
  const { colorMode } = useColorMode();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
     <Marquee direction="left" speed={50}>
      <Box
        textAlign="center"
        mt="20px"
        mb="20px"
        style={{ backgroundColor: colorMode === "light" ? "#fafafa" : "#345430" }}
      >
        <a
          href="https://play.google.com/store/apps/details?id=com.adefolarin.ewe"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={GooglePlay}
            alt="Download Our App on Google Play"
            style={{
              maxWidth: "200px",
              width: "100%",
              height: "auto",
              display: "block",
              margin: "0 auto",
            }}
          />
        </a>
        <Button
          as="a"
          href="https://play.google.com/store/apps/details?id=com.adefolarin.ewe"
          target="_blank"
          rel="noopener noreferrer"
          colorScheme="teal"
          mt="20px"
        >
          Get the App
        </Button>
      </Box>
      </Marquee>
    </motion.div>
  );
};

export default DownloadAppSection;
