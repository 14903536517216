import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {  RootState } from "../types/interface";
import { selectAccessToken } from '../slice/userSlice';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const postApiSlice = createApi({
    reducerPath: 'postsApi',
    baseQuery: fetchBaseQuery({
      baseUrl: BASE_URL,
      prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState | undefined;
        const accessToken = state ? selectAccessToken(state) : '';
        if (accessToken) {
          headers.set('Authorization', `Bearer ${accessToken}`);
        }
        headers.set('Content-Type', 'application/json');
        // headers.set('Access-Control-Allow-Origin', '*/*');
        // headers.set('Content-Type', 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
      },
    }),
    
    tagTypes: [ 'Posts', 'PostSearchQuery', 'UserProfile' ],
    endpoints: (builder) => ({
      createPost: builder.mutation({
        query: ({ userId, body }) => {
          return {
            url: `users/${userId}/posts`,
            method: 'POST',
            body: body,
            invalidatesTags: [{ type: "Posts"}],
          };
        },      
        onQueryStarted: ({ body }) => {
          console.log('Mutation query started. Body:', body);
        },
      }),      
      getAllPosts: builder.query({
        query: () => `posts`, // Define the query URL
        providesTags: ['Posts']
      }),
      getPost: builder.query({
        query: (postId) => {
            return {
              url: `posts/${postId}`,
              invalidatesTags: [{ type: "Posts"}],
            };
          }
      }),
      getUserPost: builder.query({
        query: (userId) => ({
          url: `users/${userId}/posts`,
          invalidatesTags: [{ type: "Posts", id: "LIST" }],
        })
      }),
      updatePost: builder.mutation({
        query: ({ userId, id, updatedPost }) => ({
          url: `users/${userId}/posts/${id}/edit`,
          method: "PATCH",
          body: {
            ...updatedPost,
          },
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Posts", id: arg.id }],
      }),
      deletePost: builder.mutation({
        query: ({ userId, postId }) => ({
          url: `users/${userId}/posts/${postId}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Posts", id: arg.id }],
      }),
      getPostSearchQuery: builder.query({
        query: ({ limit, page, search }) => `posts?limit=${limit}&page=${page}&search=${search}`,
      }),   
      likePost: builder.mutation({
        query: ({ userId, postId }) => ({
            url: `users/${userId}/posts/${postId}/like`,
            method: 'PATCH',
        }),
    }),
    dislikePost: builder.mutation({
        query: ({ userId, postId }) => ({
            url: `users/${userId}/posts/${postId}/dislike`,
            method: 'PATCH',
        }),
    }),
    commentOnPost: builder.mutation({
      query: ({ userId, postId, comment }) => ({
          url: `users/${userId}/posts/${postId}/comment`,
          method: 'PATCH',
          body: comment,
      }),
  }),
  deleteCommentOnPost: builder.mutation({
    query: ({ userId, postId, commentId }) => ({
      url: `users/${userId}/posts/${postId}/comment/${commentId}`,
      method: 'DELETE',
    }),
  }),  
//   deleteCommentOnPost: builder.mutation({
//     query: ({ userId, postId }) => ({
//       url: `users/${userId}/posts/${postId}/comment`,
//       method: 'DELETE',
//     }),
// }),
    bookmarkAPost: builder.mutation({
      query: ({ userId, postId }) => ({
          url: `users/${userId}/bookmarks/${postId}`,
          method: 'POST',
      }),
  }),
    getBookmarks: builder.query({
      query: (userId) => ({
          url: `users/${userId}/bookmarks`,
      }),
    }),
    deleteBookmark: builder.mutation({
      query: ({ userId, postId }) => ({
          url: `users/${userId}/bookmarks/${postId}`,
          method: 'DELETE'
      }),
    }),
    getUserProfile: builder.query({
        query: (userId) => ({
          url: `users/${userId}/profile`,
          providesTags: ['UserProfile'],
        })
      }),
      createOrder: builder.mutation({
        query: (body) => ({
          url: 'store/orders',
          method: 'POST',
          body,
          providesTags: ['Orders'],
          defer: true,
        }),
      }),
      getItems: builder.query({
        query: () => ({
          url: 'store/items',
          providesTags: ['Orders'],
          defer: true,
        }),
      }),
    }),
  });
  
  export const {
    useGetAllPostsQuery,
    useLazyGetAllPostsQuery,
    useCreatePostMutation, 
    useLazyGetPostQuery,
    useLazyGetUserPostQuery,
    useLazyGetBookmarksQuery,
    useUpdatePostMutation,
    useDeletePostMutation,
    useGetPostSearchQueryQuery,
    useGetUserPostQuery,
    useDislikePostMutation,
    useLikePostMutation,
    useCommentOnPostMutation,
    useGetPostQuery,
    useGetUserProfileQuery,
    useBookmarkAPostMutation,
    useDeleteBookmarkMutation,
    useGetBookmarksQuery,
    useCreateOrderMutation,
    useGetItemsQuery,
    useDeleteCommentOnPostMutation
  } = postApiSlice;