import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";
import { RootState, Store } from "../types/interface";

// Define the type for the stores state
export interface StoresState extends EntityState<Store, string> {
  ids: string[];
  entities: Record<string, Store>;
  phoneNumber: string | null; 
}
// Setup the entity adapter
export const storesAdapter = createEntityAdapter<Store, EntityId>({
  selectId: (store) => store._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

// Thunk to fetch stores from the API
export const fetchStores = createAsyncThunk("stores/fetchStores", async () => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}stores`);
  // console.log("Store Response:", response);
  const data = await response.json();
  return data.data.filter((store: Store) => !store.deactivated);
});


const initialState: StoresState = storesAdapter.getInitialState({
  ids: [],
  entities: {},
  phoneNumber: null,
});


// Create the slice
const storeSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    addStore: storesAdapter.addOne,
    addStores: storesAdapter.addMany,
    setStorePhoneNumber(state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStores.fulfilled, (state, action) => {
      storesAdapter.setAll(state, action.payload); // Populate state with fetched stores
    });
  },
});

export const { addStore, addStores, setStorePhoneNumber } = storeSlice.actions;

// Selector to check if a user has a store

const selectAllStores = storesAdapter.getSelectors<RootState>((state) => state.stores).selectAll;


// Selector to get active stores
const selectActiveStores = createSelector(
  selectAllStores, // Use the memoized selector
  (stores) => stores.filter((store) => !store.deactivated)
);

export const selectUserHasStore = createSelector(
  selectActiveStores,
  // (state: RootState) => storesAdapter.getSelectors().selectAll(state?.stores),
  (_: RootState, userId: string | number | undefined) => userId?.toString(),
  (activeStores, userId) => {
    // console.log("Checking User Store:", { stores, userId }); // Debugging
    // return !!userId && stores.some((store) => store?.user?.toString() || store?._id === userId);
    return (
      !!userId && activeStores.some((store: any) => store.user?._id === userId)
    );
  }
);
export default storeSlice.reducer;
