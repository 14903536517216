import React, { useEffect, useState } from "react";
import { Box, Text, Image, Flex, useColorMode, Divider, Button } from "@chakra-ui/react";
import { Answer, Question, User } from "../../types/interface";
import { Link, useParams } from "react-router-dom";
import formatCreatedAtDate from "../Date";
import { selectCurrentUser, selectIsAuthenticated } from "../../slice/userSlice";
import { useSelector } from "react-redux";
import QuestionMenu from "./QuestionMenu";
import QuestionTranslationToggle from "./QuestionTranslationToggle";
import { BsFillShareFill } from "react-icons/bs";
import ShareButton from "../ShareButton";

interface QuestionCardProps {
  question: Question;
  onQuestionClick: (id: string) => void;
  updatedAnswersData: (questionId: string, updatedAnswers: Answer[]) => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({ question, onQuestionClick, updatedAnswersData }) => {
  const { colorMode } = useColorMode();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [translatedQuestion, setTranslatedQuestion] = useState<Partial<Question> | null>(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const validUserId = userId || currentUser?.id;

  // Reset translated question when the question changes
  useEffect(() => {
    setTranslatedQuestion(null); // Reset to null when the question changes
  }, [question]);


  const toggleShareModal = () => {
    setIsShareModalOpen(prev => !prev);
  };


  return (
    <Box key={question._id} width="100%" mx="auto" mb="4" borderRadius="10px" bg="transparent" border="none"  color={colorMode === 'light' ? '#000' : 'white'}>
      {/* Content */}
      <Flex justify="space-between" alignItems="center" mb={4}>
        <Flex alignItems="center">
          <Box>
            <Link to={`/user/${question?.user?._id}`}>
              <Text
               textAlign="start"
               paddingBottom="5px"
               fontFamily="Inter"
               paddingTop="5px"
               fontWeight={600}
               fontSize={{ base: "16px", md: "18px", lg: "20px" }}
             >
                {isAuthenticated ? (
                  <Link to={`/user/${question?.user?._id}`}>@{question?.user?.username}</Link>
                ) : (
                  <Link to={'/login'}/>
                )}
              </Text>
            </Link>
            <Text
              textAlign="start"
              fontFamily="Inter"
              fontWeight={600}
              fontSize={16}
              color={colorMode === "light" ? "#345430" : "white"}
            >
              {formatCreatedAtDate(question?.createdAt)}
            </Text>
          </Box>
        </Flex>
        {isAuthenticated ? (
          <QuestionMenu questionId={question?._id} userId={question?.user?._id} />
        ) : (
         <Link to={'/login'}/>
        )}
      </Flex>


      <Box mb={4} onClick={() => onQuestionClick(question._id)}>
        {question?.images?.length > 0  && question.images[0] !== '' && (
          // <Image src={question.images[0]} alt="Questions" borderRadius="10px" />
          <Box
          flex={{ base: "0 0 40%", md: "0 0 40%" }}
          maxWidth={{ base: "100vw", md: "100vw" }}
          width="100%" 
          height="500px"
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          borderRadius="10px" 
          objectFit="cover"
          // ref={ref}
        >
          <Image
            width={{ base: "100vw", md: "500px", lg:"500px" }} 
             height="100%"
                      //  objectFit="cover"
          borderRadius={"10px"}
          boxSize={'100%'}
          src={question.images[0]}
          alt="Questions"
          onDragStart={() => false}
        />
        </Box>
        )}
        <Text    fontFamily="Inter"
      fontSize={{ base: "16px", md: "18px", lg: "20px" }}
              whiteSpace="pre-wrap"
               className="no-select"
               mt={2} mb={2}>
          {translatedQuestion ? translatedQuestion?.question : question?.question}
        </Text>
      </Box>
      <Box
        textAlign={'start'}
        mt={5}
        mb={5}
        flex={['1', '1', 'auto']}
        position="relative"
        display="flex"
        justifyContent={'flex-start'}
      >
        <Button
          variant="ghost"
          color={colorMode === 'light' ? 'white' :  '#345430'}
          backgroundColor={colorMode === 'light' ? '#345430' :  'white'}
          leftIcon={<BsFillShareFill fontSize={20} color={colorMode === 'light' ? 'white' :  '#345430'} />}
          onClick={toggleShareModal}
        >
          Share
        </Button>
        {isShareModalOpen && <ShareButton 
        postUrl={`https://www.ewe.com.ng/user/${validUserId}/posts/${question?._id}`} 
        onClose={toggleShareModal}
        />}
      </Box>
      {question && (
        <Box>
          <QuestionTranslationToggle
            questionId={question?._id ?? ""}
            question={question?.question ?? ""}
            setTranslatedQuestion={setTranslatedQuestion}
            questionLang={question?.lang ?? "en"}
          />
        </Box>
      )}

      <Box mt={4} mb={4}>
        <Text fontSize={16} color={colorMode === "light" ? "#345430" : "white"} onClick={() => onQuestionClick(question?._id)}>
          {question?.answers?.length} answers
        </Text>
      </Box>

      <Divider />
    </Box>
  );
};

export default QuestionCard;
