import { Box, Button, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion"; // Import motion

import {
    Banner,
  CountComponent,
  DidYouKnow,
  Header,
  Sponsors,
  HomeCollections,
  NatureExplorer,
  SubscribeToNewsletter,
  FooterBox,
  UserLocation
} from "../components";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../slice/userSlice";
import ShopExplorer from "../components/ShopExplorer";
// import GooglePlay from '../assets/Google_Play_Store_badge_EN.svg-1.webp';
import DownloadAppSection from "../components/DownloadAppSection";

function Home() {
  const { colorMode } = useColorMode();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const renderUserLocation = isAuthenticated && <UserLocation />;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      className="zoom"
    >
        <Helmet>
        <meta charSet="utf-8" />
        <title>Ewé - Home Page</title>
      </Helmet>
      <Header />
      <Banner/>
      <Box
          paddingTop="20px"
          paddingBottom="20px"
        >
      <CountComponent />
      <DownloadAppSection/>
      <ShopExplorer/>
      </Box>
      <motion.div
        style={{ backgroundColor:  colorMode === 'light' ? '#fafafa' : '#345430' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeOut", duration: 2 }}
      >

                <HomeCollections />
                  <DidYouKnow />
                  <NatureExplorer/>
                  <div
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  marginLeft: "20px",
                  marginRight: '20px'
                }}
              >
                <FooterBox />
                <DownloadAppSection/>
                <Sponsors />
                <SubscribeToNewsletter />
              </div>
          </motion.div>
          {renderUserLocation}
    </motion.div>
  );
}

export default Home;
