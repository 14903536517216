import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetStoresQuery, useGetAllProductsQuery } from '../../api/stores';
import { Box, Text, Flex, Image, Center, Spinner, HStack, Tag, useColorMode, Button, Input, MenuButton, MenuList, Menu, MenuItem, Grid } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Product, ProductVariant, RootState, Store } from '../../types/interface';
// import { selectCurrentUser } from '../../slice/userSlice';
// import { User } from '../UserProfile/UserInfo';
import { useNavigate } from 'react-router-dom';
import { ShopShareButton } from '../../components';
import { BsFillShareFill } from 'react-icons/bs';
import { FaChevronDown } from 'react-icons/fa';


enum ItemCategory {
  OIL = 'Oils',
  SOAP = 'Soaps',
  HERBAL_MEDICINE = 'Herbal Medicines',
  SPICE = 'Spices',
  HERBS = 'Herbs',
  SEED = 'Seeds',
  SPIRITUAL_ITEMS = 'Spiritual Items',
  HERBAL_TEA = 'Herbal Teas',
  CANDLES = 'Candles',
  BEADS = 'Beads',
  PLANTS = "Plants"
}

const PRODUCTS_PER_PAGE = 20;

const Stores = () => {
  const { colorMode } = useColorMode();
  // const {userId} = useParams(); // Get the post ID from URL params
  const { data: stores, isLoading: storesLoading, error: storesError } = useGetStoresQuery({});
  const { data: products, isLoading: productsLoading } = useGetAllProductsQuery({})
  const [shareModalVisibility, setShareModalVisibility] = useState<any>({});
  // const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const [selectedSize, setSelectedSize] = useState<string >('');
  const [expandedStates, setExpandedStates] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  const toggleExpand = (productId: string) => {
    setExpandedStates((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };
 const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [currentCurrency, setCurrentCurrency] = useState('$');
  // console.log("Products: ", products);
  const language = useSelector((state: RootState) => state.language.language);

  const filteredStores = stores?.data?.filter((store: Store) =>
    store?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    store?.category?.some((cat) => cat.toLowerCase().includes(searchTerm.toLowerCase())) // Ensure category is included
  );

  const filteredProducts = products?.data?.filter((product: Product) => {
    const matchesSearchTerm =
      product?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product?.description?.toLowerCase().includes(searchTerm.toLowerCase());
      product?.category?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesCategory =
      selectedCategory === 'All' ||
      (product?.category && product?.category?.toLowerCase() === selectedCategory.toLowerCase());

    return matchesSearchTerm && matchesCategory;
  });

  const currentProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * PRODUCTS_PER_PAGE;
    const endIndex = startIndex + PRODUCTS_PER_PAGE;
    return filteredProducts?.slice(startIndex, endIndex) || [];
  }, [filteredProducts, currentPage]);

  const handleProductClick = (product: Product, storeId: string ) => {
    setSelectedSize(product.prices?.[0]?.size || '');
    navigate(`/stores/${storeId}/products/${product._id}`);
  };


  const toggleShareModal = (productId: string) => {
    setShareModalVisibility((prevState: any) => ({
      ...prevState,
      [productId]: !prevState[productId], // Toggle the modal visibility for the product
    }));
  };

  const toggleCurrency = () => {
    setCurrentCurrency(currentCurrency === '$' ? '₦' : '$');
  };

  useEffect(() => {
    if (products?.length > 0 && products[0]?.prices?.length > 0) {
      setSelectedSize(products[0]?.prices[0]?.size);
    }
  }, [products]);
  

  
  const getPriceInCurrency = (
    price: Product | ProductVariant | number, // Allow numbers for totals
    showDollar: boolean
  ) => {
    if (typeof price === 'number') {
      return showDollar ? `${price} $` : `${price} ₦`; // Handle totals
    }
  
    if (!price) return 'N/A'; // Handle undefined price gracefully
  
    // Check if the type is ProductVariant
    if ('price_dollar' in price && 'price_naira' in price) {
      return showDollar ? `${price.price_dollar} $` : `${price.price_naira} ₦`;
    }
  
    // Handle Product type
    if ('dollar_price' in price && 'naira_price' in price) {
      return showDollar ? `${price.dollar_price} $` : `${price.naira_price} ₦`;
    }
  
    return 'N/A'; // Fallback if none match
  };
  
  const handleCategorySelect = (category: string) => setSelectedCategory(category);

  if (storesLoading || productsLoading) {
    return (
      <Center h="100vh" flexDirection="column">
        <Spinner size="lg" color="teal.500" mb={4} />
        <Text fontSize="xl" color="teal.600">Loading...</Text>
      </Center>
    );
  }

  if (storesError) return <Text>Error loading stores</Text>;

  const handleStoreClick = (storeId: string) => {
    navigate(`/stores/${storeId}`);
  };

 // Filtered products based on search term and categor

// Total number of pages
const totalPages = Math.ceil(filteredProducts.length / PRODUCTS_PER_PAGE);


// Handle page change
const handlePageChange = (page: number) => {
  if (page > 0 && page <= totalPages) {
    setCurrentPage(page);
  }
};
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <Helmet><title>Store Page</title></Helmet>
      <Box padding="10px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
        <Text 
        fontSize={{ base: 20, sm: 25, md: 30 }} 
        fontWeight="bold"
        mb={6}
        ml={5}>
          {language === "en"
            ? "Welcome to Ewe's E-commerce Store"
            : language === "yo"
            ? "Kaabọ si Ile-itaja Ayelujara Ewe"
            : "Bienvenido a la Tienda de Comercio Electrónico de Ewe"}
        </Text>
        <Box display="flex"  ml={5} mr={5} flexDir={{ base: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" mb={6}>
          <Input
            placeholder="Search products and stores..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={{ base: '100%', md: '60%' }}
            mb={{ base: 4, md: 0 }}
          />
            <Flex
        flexDir={"row" } // Stack on small screens
        justifyContent={{ sm: "center", md: "space-between" }} // Center or space-between
        alignItems="center"
        gap={4} // Add spacing between items
      >
           <Menu>
            <MenuButton as={Button} rightIcon={<FaChevronDown />}>
              {selectedCategory}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleCategorySelect('All')}>All</MenuItem>
              {Object.values(ItemCategory).map((category) => (
                <MenuItem key={category} onClick={() => handleCategorySelect(category)}>
                  {category}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Button ml={{ base: 0, md: 4 }} onClick={toggleCurrency} w={{ base: '100%', md: 'auto' }}>
            {`Switch to ${currentCurrency === '$' ? '₦' : '$'}`}
          </Button>
      </Flex>
        </Box>


        <Text 
        fontSize={{ base: 20, sm: 25, md: 30 }} 
            fontWeight="bold" 
            mb={10}
            ml={5}>
            {language === "en"
              ? "Featured Stores"
              : language === "yo"
              ? "Awon Ile-itaja wa"
              : "Tiendas Destacadas"}
          </Text>
        {/* Store List Section */}
        <Grid 
        templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
        gap={5}
        my={5}
        ml={5}
      >
          {filteredStores?.map((store: Store) => (
            <Box
              key={store?._id}
              borderRadius="lg"
              overflow="hidden"
              p="4"
              borderWidth="1px"
              fontFamily="Inter"
              cursor="pointer"
              // w={{ base: "40%", sm: "48%", md: "100%" }} // Responsive widths
            >
              <Image
                src={store?.image}
                alt={store?.name}
                width="200px"
                height={'200px'}
                borderRadius="8px"
                objectFit="cover"
                bg="white"
              />
              <Text mt={2}   fontSize={{ base: 16, sm: 20, md: 25 }}  fontWeight="bold">
                {store?.name}
              </Text>
              <Text  fontSize={{ base: 16, sm: 20, md: 25 }}   fontWeight="bold" mb={2}>Categories:</Text>
              <HStack spacing={2} wrap="wrap">
                {store?.category?.map((cat, index) => (
                  <Tag  fontSize={{ base: 12, sm: 16, md: 20 }}   key={index} colorScheme="teal">
                    {cat}
                  </Tag>
                ))}
              </HStack>
              <Button
                mt={3}
                fontFamily="Inter"
                cursor="pointer"
                onClick={() => handleStoreClick(store?._id)}
                fontSize={{ base: 16, sm: 20, md: 25 }}  
              >
                View Store
              </Button>
            </Box>
          ))}
          {filteredStores?.length === 0 && <Text>No stores match your search</Text>}
        </Grid>


        {/* Featured Products Section */}
        <Text 
        fontSize={{ base: 20, sm: 25, md: 30 }} 
        mb={5}
        ml={5}
        fontWeight="bold">
          {language === "en"
            ? "Featured Products"
            : language === "yo"
            ? "Awọn ọja wa"
            : "Productos Destacados"}
        </Text>

       <Grid 
        templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
               gap={4} // Adjust the gap for spacing between items
               px={{ base: 2, sm: 2, md: 6 }}>
           {currentProducts?.map((product: Product) => (
                 <Box
                 key={product._id}
                 borderWidth="1px"
                 borderRadius="lg" 
                 overflow="visible"
                 p="2"
                 // bg={colorMode === 'light' ? 'white' : 'gray.800'}
                 fontFamily="Inter"
                 cursor="pointer" // Optional: adds a pointer cursor on hover
            //  w={{ base: "80%", sm: "48%", md: "100%" }}
                 _hover={{ boxShadow: 'xl' }}
                 objectFit="cover"
               >
             
                 <Image src={product?.images[0]} 
                 alt={product?.name} 
                    width="500px"
                    height={'500px'}
                    borderRadius="8px"
                    objectFit="cover"
                    mb={4} />
                <Text mt={2} fontWeight="bold">{product.name}</Text>
                <Text 
                mt={2} 
                fontWeight="bold" 
                color="blue.500" 
                cursor="pointer" 
                _hover={{ textDecoration: 'underline', color: 'blue.600' }}
                // onClick={() => handleStoreClick(product?.store?._id, product?.store?.user?._id,)}
              >
                Store Name: {product?.store?.name}
              </Text>
              <Text
                   paddingBottom="5px"
                   textAlign="start"
                   fontFamily="Inter"
                   fontSize={12}
                             >
                    <strong>Product Category:</strong> { product?.category ?? 'N/A'}
                </Text>

                <Box
                onClick={() => handleProductClick(product, product?.store?._id)}
                                >
               <Text fontSize="sm" mb={2}>
                {product?.description?.length > 100 && !expandedStates[product?._id]
                  ? `${product?.description?.substring(0, 100)}...`
                  : product?.description}
              </Text>
              {product?.description?.length > 100 && (
                <Button
                  size="sm"
                  variant="link"
                  onClick={(e) => {
                    e.stopPropagation(); 
                    toggleExpand(product._id);
                  }}
                >
                  {expandedStates[product._id] ? 'Show Less' : 'Read More'}
                </Button>
              )}


              <Box display="flex" alignItems="center" mb="4">
            <Text fontWeight="bold" fontSize="xl" color="teal.600" mr="4">
              Price:
            </Text>
            <Text fontSize="xl">
              {getPriceInCurrency(product,  currentCurrency === '$')}
            </Text>
          </Box>

                </Box>
                <Box
                 display="flex" 
                 flexDirection={'row' } 
                 justifyContent="center" 
                 alignItems="center" 
                 mt={4}
                 gap={5}>

                 
                <Button
                      fontWeight="bold"
                      py={2}
                      px={4}
                      borderRadius={5}
                      onClick={() => handleProductClick(product, product?.store?._id)}
                      w="100%"
                    >
                      {language === 'en' ? 'Add to Cart' : language === 'yo' ? 'Fí Sí Cart' : 'Añadir al carrito'}
                    </Button>

                   
                <Box
                  mt={5}
                  mb={5}
                  flex={['1', '1', 'auto']}
                  position="relative"
                  display="flex"
                  justifyContent={'flex-end'}
              overflow="visible"
                >
                  <Button
                    variant="ghost"
                    color={colorMode === 'light' ? 'white' : '#345430'}
                    backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                    onClick={() => toggleShareModal(`${product._id}`)}
                    w="100%"
                  >
                    <BsFillShareFill />
                  </Button>


                  {shareModalVisibility[product._id] &&  
                    <ShopShareButton
                    productUrl={`https://www.ewe.com.ng/stores/${product?.store?._id}/products/${product?._id}`}
                      onClose={() => toggleShareModal(product._id)}
                    />
                  }
                </Box>
                </Box>
              </Box>
              ))}
          {/* {filteredProducts?.length === 0 && <Text>No products match your search</Text>} */}
        </Grid>
          {/* Pagination Controls */}
      <Flex justifyContent="center" alignItems="center" mt="8" mb={8}>
        <Button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          mr="4"
        >
          Previous
        </Button>
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          ml="4"
        >
          Next
        </Button>
      </Flex>
      </Box>
    </motion.div>
  );
};

export default Stores;