// VerifyEmail.js
import React, { useState } from 'react';
import { Button, Container, Text, Input, useColorMode, Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import ToastNotification from '../components/ToastNotification'; // Import your ToastNotification component
import AuthCall from "../api/auth";
import { useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
    const [code, setCode] = useState('');
    const { useVerifyEmailMutation } = AuthCall();
    const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
    const { colorMode } = useColorMode();
    const [toastConfig, setToastConfig] = useState({ description: '', status: '', show: false });
    const navigate = useNavigate(); // Get navigate from useNavigate
  
    const handleVerify = async () => {
      try {
        const response = await verifyEmail({ code }); // Adjust this based on your API requirements
        if ('data' in response) {
          setToastConfig({
            description: "Your email has been verified!",
            status: 'success',
            show: true,
          });
          // Navigate to home after verification
          navigate("/"); // Redirect to home after successful verification
        } else if ('error' in response) {
          const errorMessage = "Verification failed.";
          setToastConfig({
            description: errorMessage,
            status: 'error',
            show: true,
          });
        }
      } catch (error) {
        setToastConfig({
          description: 'Verification failed.',
          status: 'error',
          show: true,
        });
      }
    };
  
    return (
      <Container centerContent>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Email Verification</title>
        </Helmet>
        <Box
        mb={5}>
        <Text fontSize="xl" fontWeight="bold">Verify Your Email</Text>
        <Input
          placeholder="Enter verification code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          marginY={4}
        />
        <Button 
          isLoading={isLoading} 
          onClick={handleVerify}

          backgroundColor={colorMode === 'light' ? '#345430' : "white"}
          color={colorMode === 'light' ?  "white" : '#345430'}
        >
          Verify Email
        </Button>
          </Box>
        {toastConfig.show && (
          <ToastNotification 
            description={toastConfig.description} 
            status={toastConfig.status} 
            onClose={() => setToastConfig({ ...toastConfig, show: false })}
          />
        )}
      </Container>
    );
  };
  

export default VerifyEmail;
