import React from "react";
import { Input, InputGroup, InputRightElement, Box, useColorMode, Spinner } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState, User } from "../../types/interface";
import { useNavigate } from "react-router-dom";
import { selectCurrentUser } from "../../slice/userSlice";

interface SearchInputProps {
  backgroundColor?: string;
  query: string;
  updateQuery: (query: string) => void;
  isLoading: boolean;
  onSubmit: (query: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ backgroundColor, query, updateQuery, isLoading, onSubmit }) => {
  const language = useSelector((state: RootState) => state.language.language);
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
    const currentUser = useSelector(selectCurrentUser) as User;  
    
  const handleSearch = () => {
    if (!currentUser) {
      navigate('/login'); // Redirect unauthenticated users to login
      return;
    }
  
    if (query.trim() !== '') {
      onSubmit(query);
      navigate(`/user/${currentUser.id}/posts?query=${query}`);
    }
  };  
  
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // Only trigger search when there's a non-empty query
      if (query.trim() !== '') {
        handleSearch();
      }
    }
  };

  return (
    <Box width={'100%'}>
      <InputGroup>
        <Input
          type="text"
          placeholder={
            language === 'en'
              ? 'Type any Yoruba ecological search here ...'
              : language === 'yo'
              ? 'Tẹ nkan tón wa tó bá wù ẹ́ síbí kí o sì tẹ “Enter”"...'
              : 'Escribe cualquier búsqueda ecológica en Yoruba aquí...'
          }
          value={query}
          onChange={(e) => {
            updateQuery(e.target.value); // Update query state
            // handleSearch(e.target.value); // Trigger search on each change 
          }}
          onKeyDown={handleKeyDown}
          backgroundColor={backgroundColor}
          fontFamily="Inter"
          fontSize="20px"
          height={'60px'}
          color="#000"
          _placeholder={{ color: 'black' }}
          _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
          border={`2px solid ${colorMode === 'light' ? '#345430' : 'white'}`}
        />
        <InputRightElement
          pointerEvents="auto" // Enable pointer events
          backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
          width="60px"
          height="60px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => handleSearch()} // Trigger search on click
        >
          {isLoading ? (
            <Spinner size="sm" color="white" />
          ) : (
            <FaSearch color={colorMode === 'light' ? '#fff' : '#345430'} fontSize={25} />
          )}
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default SearchInput;