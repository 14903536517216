import React, { useEffect, useState } from 'react';
import { Box, Text, useColorMode, HStack, VStack, Badge, Button, Image, Flex, useClipboard } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AutoBackButton, ToastNotification } from '../../components';
import { useDeleteStoreMutation, useGetStoreByUserIdQuery } from '../../api/stores';
import { Store, User, RootState } from '../../types/interface';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import StoreProducts from './StoreProducts';
import { useNavigate } from 'react-router-dom';
import { FiDelete, FiEdit } from 'react-icons/fi';
import {  fetchStores, selectUserHasStore } from '../../slice/storeSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';


const PersonalUserStores = () => {
  const { userId } = useParams(); // Get the user ID from URL params
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // Selectors
  const currentUser = useSelector(selectCurrentUser) as User;
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const language = useSelector((state: RootState) => state.language.language);
  const userHasStore = useSelector((state: RootState) => selectUserHasStore(state, currentUser?.id));
  
  // Fetch store data
  const { data: store, isLoading, isError } = useGetStoreByUserIdQuery(userId);
  const [deleteStore, { isLoading: isDeleting }] = useDeleteStoreMutation();

  // State
  const [storeData, setStoreData] = useState<Store[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: "", status: "" });
  
  useEffect(() => {
    if (store) {
      setStoreData(store.data);
    }
  }, [store]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchStores());
    }
  }, [isAuthenticated, dispatch]);

  const canCreateArticle = () => currentUser?.role === 'SPECIALIST';

  const handleDeleteStore = (storeId: string) => {
    setToastConfig({ 
      description: "To delete your store, please send an email to contact@ewe.com.ng", 
      status: "info" 
    });
    setShowToast(true);
  };

  const redirectToCreatePost = () => {
    if (isAuthenticated) {
      navigate(`/user/${currentUser.id}/create-store`);
    } else {
      navigate('/login'); 
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{storeData.length > 0 ? 'Store Details' : 'Store Listings'}</title>
      </Helmet>
      <AutoBackButton />
      <Box>
        {isLoading ? (
          <Box textAlign="center" fontSize="18px" fontWeight="bold">Loading store details...</Box>
        ) : isError ? (
          <Box textAlign="center" fontSize="18px" fontWeight="bold">Error loading store details.</Box>
        ) : storeData.length === 0 ? (
          <Box textAlign="center" fontSize="18px" mb={4} fontWeight="bold">
            <Text mb={4}>
              {language === 'en' ? "You haven't created any store" : language === 'yo' ? "E kò ti dá ọjà kankan ṣilẹ" : "No has creado ninguna tienda"}
            </Text>
            {canCreateArticle() && !userHasStore && currentUser && (
              <Button onClick={redirectToCreatePost} bg={colorMode === 'light' ? '#345430' : 'white'}
                color={colorMode === 'light' ? 'white' : '#345430'} borderRadius="10px" size="md">
                {language === 'en' ? 'Create Store' : language === 'yo' ? 'Sí Ọjà' : 'Crear Tienda'}
              </Button>
            )}
          </Box>
        ) : (
          storeData.map((storeItem) => {
            const isOwner = currentUser?.id === storeItem?.user?._id;
            return (
              <Box key={storeItem._id} padding="20px" borderRadius="10px">
                <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="start" gap={5}>
                  <Image
                    src={storeItem?.image}
                    alt={storeItem?.name}
                    borderRadius="8px"
                    width="100%"
                    height={'350px'}
                    objectFit="cover"
                    boxSize={200}
                    style={{
                      backgroundColor: colorMode === "light" ? "#E0EAE0" : "white",
                      color: colorMode === 'light' ? "white" : "#345430",
                    }}
                  />
                  <VStack align="start" spacing={4} flex="1">
                    <Text fontSize={{ base: '20px', md: '30px' }} fontWeight="bold">{storeItem?.name}</Text>
                    <Text fontSize="sm">{storeItem?.description}</Text>
                    <HStack wrap="wrap" spacing={2}>
                      <Text fontWeight="bold">Categories:</Text>
                      {storeItem?.category?.length ? (
                        storeItem.category.map((cat) => <Badge key={cat} colorScheme="green">{cat}</Badge>)
                      ) : (
                        <Text color="gray.500">No categories available</Text>
                      )}
                    </HStack>
                    <HStack>
                      <Text><strong>Status:</strong> <Badge colorScheme={storeItem?.deactivated ? 'red' : 'green'}>{storeItem?.deactivated ? 'Deactivated' : 'Active'}</Badge></Text>
                    </HStack>
                    {isOwner && <Text><strong>Tokens Available:</strong> {storeItem?.tokenAvailable ?? 'N/A'}</Text>}
                    <Text><strong>Store Phone:</strong> {storeItem?.phoneNumber ?? 'N/A'}</Text>
                    {isOwner && (
                      <>
                        <Button colorScheme="blue" onClick={() => navigate(`/user/${currentUser?.id}/stores/${storeItem?._id}/edit-store`)}><FiEdit /> Edit Store</Button>
                        <Button colorScheme="red" isLoading={isDeleting} onClick={() => handleDeleteStore(storeItem._id)}><FiDelete /> Delete Store</Button>
                        {!storeItem?.deactivated && storeItem?.tokenAvailable > 0 && (
                          <Link to={`/user/${currentUser.id}/stores/${storeItem._id}/create-products?maxProducts=${storeItem?.tokenAvailable}`}>
                            <Button colorScheme="teal">Create Product</Button>
                          </Link>
                        )}
                      </>
                    )}
                  </VStack>
                </Flex>
                {!storeItem?.deactivated && <StoreProducts storeId={storeItem._id} storeUserId={storeItem?.user?._id} />}

                {canCreateArticle() &&
                  currentUser?.id === storeItem.user?._id && (
                    <>
                      {storeItem?.deactivated || storeItem?.tokenAvailable === 0 ? (
                        <>
                          <Text color="red.500" mt={4}>
                            {storeItem.deactivated
                              ? 'Your store is deactivated. Please send an email to contact@ewe.com.ng to activate your store.'
                              : "You've exhausted your tokens. Upgrade your plan to create more products."}
                          </Text>

                          {!storeItem?.deactivated && storeItem?.tokenAvailable === 0 && (
                            <Button
                              onClick={() => navigate(`/user/${currentUser?.id}/stores/${storeItem._id}/plan-upgrade`)}
                              backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                              color={colorMode === 'light' ? 'white' : '#345430'}
                              size="lg"
                              width="sm"
                              mt={4}
                            >
                              Upgrade Plan
                            </Button>
                          )}
                        </>
                      ) : null}
                    </>
                  )}

              </Box>

              
            );
          })
        )}
      </Box>
      {showToast && <ToastNotification description={toastConfig.description} status={toastConfig.status} onClose={() => setShowToast(false)} />}
    </motion.div>
  );
};

export default PersonalUserStores;

