import React, { useState, useEffect } from 'react';
import { Box, Button, Text, useColorMode, Textarea, IconButton } from '@chakra-ui/react';
import {  useDeleteCommentOnPostMutation } from '../../api/postApi';
import { useSelector } from 'react-redux';
import { selectAccessToken, selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import { useParams, Link } from 'react-router-dom';
import { Post, User } from '../../types/interface';
import { SubmitHandler, useForm } from 'react-hook-form';
import ToastNotification from '../ToastNotification';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
import formatCreatedAtDate from '../Date';


interface CommentComponentProps {
  post: Post; 
  updatedPostData: (newData: Partial<Post>) => void; // Update function signature
}

interface CommentFormData {
  comments: any;
  comment: string;
}

const Comment: React.FC<CommentComponentProps> = ({ post, updatedPostData }) => {
  const [comments, setComments] = useState<{ 
    user: any; 
    username: string;
     comment: string; 
    _id: string;
    createdAt: string;
   }[]>([]);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser) as User;
  const { userId } = useParams();
  const validUserId = userId || currentUser?.id;
  const { colorMode } = useColorMode();
  const accessToken = useSelector(selectAccessToken);

  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: '', status: '' });

  const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm<CommentFormData>({ criteriaMode: 'all' });

  const [deleteCommentOnPost] = useDeleteCommentOnPostMutation();

  useEffect(() => {
    if (post?.comments) {
      const transformedComments = post.comments.map(comment => ({
        user: {
          _id: comment.user?._id || '',
          username: comment.user?.username || '',
        },
        username: comment.user?.username || '',
        comment: comment.comment,
        _id: comment._id,
        createdAt: comment.createdAt || '',
      }));
      setComments(transformedComments);
    }
  }, [post]);  
  

  const handleDeleteComment = async (commentId: string) => {
    try {
      await deleteCommentOnPost({ userId: currentUser.id, postId: post?._id, commentId }).unwrap();
      setComments(prev => prev.filter(comment => comment._id !== commentId));
      setToastConfig({ description: 'Comment deleted successfully', status: 'success' });
      setShowToast(true);
    } catch (error) {
      console.error('Error deleting comment:', error);
      setToastConfig({ description: 'Failed to delete comment', status: 'error' });
      setShowToast(true);
    }
  };


const onSubmit: SubmitHandler<CommentFormData> = async (data) => {
  if (!isAuthenticated || !post?._id || data.comment.trim() === '') return;

  try {
    const response = await axios.patch(
      `https://ewe-api.herokuapp.com/api/users/${validUserId}/posts/${post._id}/comment`,
      { comment: data.comment },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );


    const comments = response.data?.data?.comments;


    // Find the newly added comment
    const newComment = comments.find(
      (c: { comment: string; user: { _id: string } }) => 
        c.comment === data.comment && c.user._id === currentUser.id
    );

    if (!newComment || !newComment._id) {
      throw new Error("Invalid API response: Missing comment ID");
    }

    const newCommentData = {
      user: {
        _id: String(currentUser.id),
        username: currentUser.username,
        name: currentUser.name || 'Unknown',
      },
      username: currentUser.username,
      comment: data.comment,
      _id: newComment._id,
      createdAt: newComment.createdAt,
    };

    // Update comments state
    setComments((prevComments) => [...prevComments, newCommentData]);

    // Update post data with new comments array
    updatedPostData({
      ...post,
      comments: [...comments, newCommentData], // Ensure previous comments are included
    });

    setToastConfig({
      description: 'Comment added successfully',
      status: 'success',
    });

    setShowToast(true);
    reset();
  } catch (error) {
    console.error('Failed to add comment:', error);
    setToastConfig({ description: 'Failed to add comment', status: 'error' });
    setShowToast(true);
  }
};


  return (
    <Box>
      {comments.map((comment, index) => (
        <Box key={index} mt={5} p={3} borderRadius="md" _hover={{ boxShadow: 'md', transform: 'scale(1.02)' }} transition="all 0.2s ease-in-out" position="relative">
          <Link to={isAuthenticated ? `/user/${comment?.user?._id}` : '/login'}>
            <Text fontSize="16px" fontWeight="bold" color={colorMode === 'light' ? '#345430' : 'white'}>
              @{comment.username}
            </Text>
          </Link>
          <Text fontSize="14px" color="gray.300">
              {formatCreatedAtDate(comment?.createdAt)}
            </Text>
          <Text fontSize="16px" color={colorMode === 'light' ? '#345430' : 'white'}>
            {comment.comment}
          </Text>

          {isAuthenticated && currentUser?.id === comment?.user?._id && (
            <IconButton
              aria-label="Delete Comment"
              icon={<FaTrash />}
              size="md"
              color="red"
              position="absolute"
              top={2}
              right={2}
              _hover={{ bg: 'red.600', transform: 'scale(1.1)' }}
              onClick={() => handleDeleteComment(comment._id)}
            />
          )}
        </Box>
      ))}

      {isAuthenticated && (
        <Box mt={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Textarea
              {...register('comment', {
                required: 'Comment is required',
                maxLength: { value: 300, message: 'Maximum character limit exceeded' }
              })}
              placeholder="Add a comment..."
              resize="vertical"
              h={150}
              color={colorMode === 'light' ? '#345430' : 'white'}
            />
            {errors.comment && (
              <Text color="red" fontSize="sm">
                {errors.comment.message}
              </Text>
            )}
            <Button
              type="submit"
              isLoading={isSubmitting}
              backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
              color={colorMode === 'light' ? 'white' : '#345430'}
              size="lg"
              width="full"
              mt={2}
            >
              Submit
            </Button>
          </form>
        </Box>
      )}

      {showToast && (
        <ToastNotification description={toastConfig.description} status={toastConfig.status} onClose={() => setShowToast(false)} />
      )}
    </Box>
  );
};

export default Comment;
