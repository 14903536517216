import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Image, Text, Box, Icon, useColorMode, Divider, Flex, Skeleton, Button } from "@chakra-ui/react";
import { GoVerified } from "react-icons/go";
import { BiError } from "react-icons/bi";
import { RootState, Post, PostContentProps } from "../../types/interface";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import PostMenu from "./PostMenu";
import PostButtons from "./PostButtons";
import formatCreatedAtDate from "../Date";
import { FaMapMarkerAlt } from "react-icons/fa";
import TranslationToggle from "./TranslationToggle";
import { getCategoryIcon } from "../getCategoryIcon";


const MAX_LENGTH = 100; 
const PostContent: React.FC<PostContentProps> = ({
  post,
  isAuthenticated,
  // currentUser,
  handlePostCardClick,
  isLoading,
  updatedPostData
}) => {
  const { colorMode } = useColorMode();
  const language = useSelector((state: RootState) => state.language.language);
  const { ref, inView } = useInView({ triggerOnce: true });
  const [translatedPost, setTranslatedPost] = useState<Partial<Post> | null>(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullUses, setShowFullUses] = useState(false);

  const toggleDescription = () => setShowFullDescription(!showFullDescription);
  const toggleUses = () => setShowFullUses(!showFullUses);
  useEffect(() => {
    setTranslatedPost(null); // Reset translated post on post change
  }, [post]);

  useEffect(() => {
    const disableRightClick = (event: MouseEvent) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', disableRightClick);
    return () => {
      document.removeEventListener('contextmenu', disableRightClick);
    };
  }, []);
  

  return (
    <Box key={post._id} width="100%" mx="auto" mb="4" borderRadius="10px" bg="transparent" border="none" flexDirection={{ base: "column", md: "row" }} color={colorMode === 'light' ? '#000' : 'white'}>
      {isLoading ? (
        <Box flex="1">
          <Skeleton height="500px" borderRadius={20} color={'transparent'} />
        </Box>
      ) : (
        <>
          <Flex justify="space-between" alignItems="center">
            <Box display="block" alignItems="start">
              <Text textAlign="start" paddingBottom="5px" fontFamily="Inter" paddingTop="5px" fontWeight={600}       fontSize={{ base: "16px", md: "18px", lg: "20px" }}>
                {isAuthenticated ? <Link to={`/user/${post?.user?._id}`}>@{post?.user?.username}</Link> : <Link to={'/login'}/>}
              </Text>
              <Text paddingBottom="5px" textAlign="start" fontFamily="Inter" fontWeight={600} fontSize={16}>
                {formatCreatedAtDate(post?.createdAt)}
              </Text>
              {post?.location && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start', paddingBottom: '8px' }}>
                      <FaMapMarkerAlt color={colorMode === 'light' ? '#345430' : 'white'} size={20} />
                      <Text color={colorMode === 'light' ? '#000' : 'white'} textAlign={'start'} pl={2} fontSize={{ base: '12px' }}>
                        {post?.location}
                      </Text>
                    </div>
                  )}
            </Box>
            {isAuthenticated && post && post.user ? <PostMenu postId={post._id} ownerId={post.user._id} postVerified={post.verify} /> : <Link to={'/login'}/>}
          </Flex>
          <Box
              flex={{ base: "0 0 100%", md: "0 0 40%" }}
              maxWidth={{ base: "100vw", md: "100vw" }}
              height={"500px"}
              ref={ref}
            >
              {inView && post?.images && post?.images?.length > 0 && post.images[0] !== ''  && (
                <Box
                flex={{ base: "0 0 40%", md: "0 0 40%" }}
                maxWidth={{ base: "100vw", md: "100vw" }}
                width={{ base: "100vw", md: "500px", lg:"500px" }} 
                height="500px"
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                borderRadius="10px" 
                objectFit="cover">
                <Image
                  // objectFit="contain"  // Changed from 'cover' to 'contain'
                  width="100%"
                  height="100%"
                  borderRadius={"10px"}
                  boxSize={'100%'}
                  src={post?.images[0]}
                  alt={post?.name}
                  onDragStart={() => false}
                />
                </Box>
              )}
            </Box>

          <Box flex="1" textAlign="start" fontFamily="Inter" onClick={() => handlePostCardClick(post._id)}>
            <Text
              textAlign="start"
              paddingBottom="10px"
              fontFamily="Inter"
              paddingTop="10px"
              fontSize={{ base: "20px", md: "20px", lg: "25px" }}
              textTransform="capitalize"
              display={'flex'}
              justifyContent={'start'}
              alignItems={'center'}
              gap={'20px'}
              className="no-select"
            >
              {post?.name}
              <span>
                <Image
                  objectFit="cover"
                  width="30px"
                  height="30px"
                  src={getCategoryIcon(post?.type, language) as string} 
                  alt={"Category"}
                  onDragStart={() => false}
                />
              </span>
              <span style={{ width: '50px', height: '50px' }}>
                <Icon borderRadius={'10px'}>
                  {post.verify ? (
                    <GoVerified style={{ color: colorMode === 'light' ? '#345430' : 'white', backgroundColor: colorMode === 'light' ? 'white' : '#345430' }} fontSize={25} />
                  ) : (
                    <BiError style={{ color: 'red', backgroundColor: 'red' }} fontSize={25} />
                  )}
                </Icon>
              </span>
            </Text>
            <Text textAlign="start" fontFamily="Inter" textTransform="capitalize" fontSize="lg" 
            mb={4}
            className="no-select">
             {language === 'en'
                  ? 'Other Names: '
                  : language === 'yo'
                  ? 'Oruko omiran: '
                  : 'Otros nombres: '}
              <span>
                {Array.isArray(post?.othernames) ? post?.othernames.join(', ') : post?.othernames}
              </span>
            </Text>
            <Text fontSize={'20px'} fontFamily="Inter" fontWeight={600} paddingBottom="10px">
              {language === 'en' ? 'Description: ' : language === 'yo' ? 'Apejuwe re:' : 'Descripción:'}
            </Text> 
                 <Text fontFamily="Inter" fontSize={16} whiteSpace="pre-wrap" className="no-select">
                            {showFullDescription || !(translatedPost?.description || post.description) || (translatedPost?.description ?? post.description).length <= MAX_LENGTH
                              ? translatedPost?.description ?? post.description
                              : `${(translatedPost?.description ?? post.description).substring(0, MAX_LENGTH)}...`}
                          </Text>
                          {(translatedPost?.description ?? post.description) && (translatedPost?.description ?? post.description).length > MAX_LENGTH && (
                            <Button onClick={toggleDescription} variant="link" colorScheme="blue">
                              {showFullDescription ? "Show Less" : "Read More"}
                            </Button>
                          )}
            <Text fontSize={'20px'} fontFamily="Inter" fontWeight={600} paddingBottom="10px" paddingTop={'10px'}>
              {language === 'en' ? 'Uses: ' : language === 'yo' ? 'Iwulo re: ' : 'Usos:'}
            </Text>
            <Text fontFamily="Inter" fontSize={16} whiteSpace="pre-wrap" className="no-select">
            {showFullDescription || !(translatedPost?.uses || post.uses) || (translatedPost?.uses ?? post.uses).length <= MAX_LENGTH
              ? translatedPost?.uses ?? post.uses
              : `${(translatedPost?.uses ?? post.uses).substring(0, MAX_LENGTH)}...`}
          </Text>
          {(translatedPost?.uses ?? post.uses) && (translatedPost?.uses ?? post.uses).length > MAX_LENGTH && (
            <Button onClick={toggleDescription} variant="link" colorScheme="blue">
              {showFullDescription ? "Show Less" : "Read More"}
            </Button>
          )}
            <Text
            paddingTop="5px"
            textAlign="start"
            fontFamily="Inter"
            fontSize={12}
          >
       {post?.updatedAt && post?.updatedAt !== post?.createdAt && (
          <>
            {formatCreatedAtDate(post?.updatedAt)} <span>(Edited)</span>
          </>
        )}
                </Text>

          </Box>
          <Box mt={4} >
              <TranslationToggle
                postId={post._id}
                description={post.description}
                uses={post.uses}
                setTranslatedPost={setTranslatedPost}
                postLang={post.lang}
              />
            </Box>
          <Box>
            <PostButtons post={post} updatedPostData={updatedPostData} />
          </Box>
        </>
      )}
      <Divider textAlign="center" fontSize="2xl" color={colorMode === 'light' ? '#345430' : 'white'} />
      <Divider textAlign="center" fontSize="2xl" color={colorMode === "light" ? "#345430" : "white"} />
    </Box>
  );
};

export default PostContent;
